import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './services.css'

function Services() {
  const services = [
    {
      title: 'Learn Export & Import',
      description: 'Enter the world of international opportunities now with experienced export companies. The Indian export industry is booming and expected to grow four fold in coming years.  We are motivated to boost export ventures from India to bring more foreign currencies to our country. You can also learn with experts. ',
      button: 'BOOK NOW'
    },
    {
      title: 'Register Company in Europe',
      description: 'We are an international management consulting firm. We work on the motto of partnership with our clients to identify opportunities and implement them. We have a better understanding of the European market, which better helps you to stand out in the international market. You also get residence permit with it.',
      button: 'BOOK NOW'
    },
    {
      title: 'Digital Nomad Visa',
      description: 'A digital nomad is someone who lives a nomadic lifestyle and uses technology to work remotely from outside their home country. A digital nomad visa is a document or program that gives someone the legal right to work remotely while residing away from their country of permanent residence. We can guide you to the right country.',
      button: 'BOOK NOW'
    },
    {
      title: 'Digital Marketing',
      description: 'When we see digital marketing, we don’t mean typical lessons on mainstream tools and expensive paid ads but result oriented where you can really feel the difference from the first day. We help in identifying the right strategy for you which includes the best platform based on your target audience.',
      button: 'BOOK NOW'
    },
    {
      title: 'Ecommerce Development',
      description: 'Making an ecommerce website is not an easy task and expensive too but with no guarantee of a successful sustainable business model. We help you create the best ecommerce website for you with the best strategy to crack the target audience at a sustainable budget.',
      button: 'BOOK NOW'
    },
    {
      title: 'Europe Tour Packages',
      description: 'When we hear about vacations in Europe, expensive Hotels, flights come to mind, but we help you plan your vacation at the best rates with the most fun experience. We can guide you and also apply for a visa on your behalf, followed by Hotel Bookings travel bookings etc at best price.',
      button: 'BOOK NOW'
    }
  ];

  return (
    <Container className="my-5 services text-start" id='services'>
      <h2 className="">Services</h2>
      <Row className='card-container'>
        {services.map((service, index) => (
          <Col md={4} className="" key={index}>
            <div className="mb-4 card">
          <h3>{service.title}</h3>
          <p>{service.description}</p>
          <div className='book-now'>

          <button className='btn-book-now'>{service.button}</button>
          </div>
        </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Services;
