import React from 'react';
import './ServicesGrid.css'; // Ensure you create and link a CSS file for styling
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import statue from '../assets/images/statue.webp'
import op from '../assets/images/op.webp'
import fp from '../assets/images/fp.jpg';
import hl from '../assets/images/hl.jpg'
import hlb from '../assets/images/hl.webp'
import candle from '../assets/images/cabdles.webp'
import sb from '../assets/images/sb.webp'
import bc from '../assets/images/bc.webp'
import medi from '../assets/images/medi.webp'
import mh from '../assets/images/mh.webp'
import spr from '../assets/images/spr.webp'
import sp from '../assets/images/sp.webp'
import tex from '../assets/images/tex.webp'
import yoga from '../assets/images/yoga.webp'
import jewel from '../assets/images/jewe.jpg'
import pet from '../assets/images/PetStoreImage.jpeg'


const B2BSection = () => {
  const items = [
    { title: 'Statues', image: statue },
    { title: 'Organic Products', image: op },
    { title: 'Fragrance products', image: fp },
    { title: 'Healthy lifestyle', image: hlb },
    { title: 'Home & Living', image: hl },
    { title: 'Candles and candle holders', image: candle },
    { title: 'Singing bowls and more', image: sb },
    { title: 'Body Care', image: bc },
    { title: 'Meditation', image: medi },
    { title: 'Minerals and gemstones', image: mh },
    { title: 'Jewellery', image: jewel },
    { title: 'Spirituality', image: spr },
    { title: 'Stationary ans packing', image: sp },
    { title: 'Textile', image: tex },
    { title: 'Pet Supply', image: pet },
    { title: 'Yoga Accessories', image: yoga },
  ];

  return (
    <Container className="services-grid container " id='categories'>
      <h2>We sell B2B only</h2>
      <div className="grid-container">
      <Row className='grid-container'>
        {items.map((item, index) => (
          <Col md={3} className="mb-3" key={index}>
          <div className="grid-item" key={index}>
            <img width={190} height={190} src={`${item.image}`} alt={item.title} />
            <div className="item-title">{item.title}</div>
          </div>
          </Col>
        ))}
      </Row>
    </div>
    </Container>
  );
};

export default B2BSection;