import React from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import HowToExport from './components/HowToExport';
import Services from './components/Services';
import B2BSection from './components/B2BSection';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css'; // Import custom CSS here

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <HowToExport />
      <Services />
      <B2BSection />
      <Footer />
    </div>
  );
}

export default App;
