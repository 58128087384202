import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="footer-bg py-4" >
      <Container id='about-us'>
        <Row>
          <Col md={5}>
            <h5>About Us</h5>
            <p className='about'>At digital kabir by Kabir innovations we are here to assist you in your end to end process of your export business. From how to start company to how to find buyer everything is included. We also assist in all compliances at domestic port to port of reach.</p>
          </Col>
          <Col md={5} className='contact'>
            <h5>Contact Us</h5>
            <p>
              <strong>Phone:</strong> +91 8929255651<br />
              <strong>Email:</strong> care@digitalkabir.com<br />
              <strong>Address:</strong> 1870, I Block 7 avenue, Gaur City Greater Noida West Uttar Pradesh 201009.
            </p>
          </Col>
          {/* <Col md={3}>
            <h5>Social Media</h5>
            <p>Links to social media</p>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
