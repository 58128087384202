import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Logo from '../assets/images/d-logo.png';
import './Header.css'; // Make sure to import your custom CSS
import '@fortawesome/fontawesome-free/css/all.min.css';

function Header() {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);
  const handleClose = () => setExpanded(false);

  return (
    <Navbar bg="white" expand="lg" expanded={expanded}>
      <Container className='d-flex align-items-center justify-content-between'>
        <Navbar.Brand href="/">
          <img src={Logo} className='navbar-logo' alt="Logo" />
        </Navbar.Brand>
        <button
          className={`close-btn ${expanded ? 'show' : ''}`}
          onClick={handleClose}
        >
          &times;
        </button>
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav" 
          className="d-lg-none custom-toggler" 
          onClick={handleToggle}
        >
          {expanded ? (
            <i className="fas fa-times"></i> // Font Awesome close icon
          ) : (
            <i className="fas fa-bars"></i> // Font Awesome hamburger icon
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className={`justify-content-center  ${expanded ? 'show' : ''}`}>
          <Nav className="text-center">
            <Nav.Link href="#how-it-works" onClick={handleClose}>How It Works</Nav.Link>
            <Nav.Link href="#services" onClick={handleClose}>Services</Nav.Link>
            <Nav.Link href="#categories" onClick={handleClose}>Categories</Nav.Link>
            <Nav.Link href="#about-us" onClick={handleClose}>About Us</Nav.Link>
            <Nav.Link href="#sell-in-india" onClick={handleClose}>Sell in India</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <button className="ml-auto register-now-link">Register Now</button>
      </Container>
    </Navbar>
  );
}

export default Header;
