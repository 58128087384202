import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaGlobe, FaClipboard, FaUsers, FaPlane } from 'react-icons/fa'; // Import icons

function HowToExport() {
  return (
    <Container className="my-5" id='how-it-works'>
      <h2 className="mb-4">How to export from India?</h2>
      <Row className="g-4">
        <Col md={6}>
          <Card className="h-100 shadow-sm export-box">
            <Card.Body>
              <div className='icon'>

                <FaGlobe size={48} className="mb-3 " />
              </div>
              <Card.Title>Decide where you want to export</Card.Title>
              <Card.Text>
                The first step is to understand global markets and the products in demand. This will help you decide what is the right marketplace for your export business.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="h-100 shadow-sm export-box">
            <Card.Body>
              <div className='icon'>
                <FaClipboard size={48} className="mb-3" />
              </div>
              <Card.Title>Get the required documentation</Card.Title>
              <Card.Text>
                Obtain PAN (from Income tax Department) and IEC (from DGFT) to export from India. Application for IEC can be filled online directly on DGFT website.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="h-100 shadow-sm export-box">
            <Card.Body>
              <div className='icon'>
                <FaUsers size={48} className="mb-3" />
              </div>
              <Card.Title>Find customers</Card.Title>
              <Card.Text>
                Starting an offline export business might need you to participate in trade fairs, buyer-seller meets, which would cost both time and money. But starting an export business is easy and simple. You can reach millions of customers on global marketplaces.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="h-100 shadow-sm export-box">
            <Card.Body>
              <div className='icon'>
                <FaPlane size={48} className="mb-3" />
              </div>
              <Card.Title>Ship and receive payments</Card.Title>

              <Card.Text>
                Timely delivery not only builds trust among your customers but also leads to repeated purchases. For payments, you can either open a bank account in the respective country or collect payments in your Indian bank account. With Global Selling, you have the flexibility of both.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default HowToExport;