import React from 'react';
import { Container, Button } from 'react-bootstrap';
import Export from '../assets/images/export.png'
import './banner.css';

function Banner() {
  return (
    <div className="banner-section row">
      <div className='col-lg-6 col-md-12 col-sm-12'>

      <Container className=" text-white">
        <h5>GLOBAL SELLING  </h5>
        <h1>How to export from India?</h1>
        <p>Starting an export business requires an understanding of compliance, logistics, and international markets. Here’s a guide for you to expand your business and reach global customers.</p>
        <button variant="warning" className='register-now-link'>Register Now</button>
        <p className="mt-2"><small>Limited period offer - Terms and conditions apply</small></p>
      </Container>
      </div>
      <div className='col-lg-6  export'>
        <img src={Export} />
      </div>
    </div>
  );
}

export default Banner;
